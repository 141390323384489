import * as Sentry from '@sentry/astro';

Sentry.init({
  dsn: 'https://277a92e2cb573dbd46863e2b5b1813e1@o4508496659349504.ingest.us.sentry.io/4508496660791296',

  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  normalizeDepth: 10,

  // Define how likely traces are sampled. Adjust this value in production,
  // or use tracesSampler for greater control.
  tracesSampleRate: 1.0,

  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.1,

  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,
});
